

document.querySelector('.b-burger').onclick = function() {
        document.querySelector('.b-burger').classList.toggle('active')
            document.querySelector('.b-menu').classList.toggle('active')

} 

//copy in buffer

    const copyBtn = document.querySelector('.b-increase-balance__copy-btn'),
        copyTarget = document.querySelector('.b-increase-balance__coin-address')
    function copyInBuffer() {
        console.log(navigator.clipboard.writeText(copyTarget.value))
        copyBtn.onclick = () => navigator.clipboard.writeText(copyTarget.value)
    }

    
